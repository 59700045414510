import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {actions, useTypedSelector} from "src/store";
import {Coordinate} from "src/store/types";

import {Address} from "../_services/types";
import {calculateDistance} from "../components/_common/Carbon";
import {useGeolocation} from "../utils/browser-storage/location";
import {
  setUserSelectedLocation,
  useUserSelectedLocation,
} from "src/utils/browser-storage/userSelectedLocation";

export const addDistToLocsByPoint = <T extends {address: Address} | {x: number; y: number}>(
  location: T,
  point: Coordinate,
  decimalPlaces = 0,
) => {
  const locationPos = {
    x: "x" in location ? location.x : location.address.latitude,
    y: "y" in location ? location.y : location.address.longitude,
  };
  return {
    ...location,
    dist: parseFloat(
      calculateDistance(point.x, point.y, locationPos.x, locationPos.y).toFixed(decimalPlaces),
    ),
  };
};

const sortLocationsByDist = <T extends {dist: number}>(a: T, b: T) => a.dist - b.dist;

export const sortLocationsByPoint = <T extends {address: Address} | {x: number; y: number}>(
  locations: T[],
  point: Coordinate,
): T[] => locations.map(loc => addDistToLocsByPoint(loc, point)).sort(sortLocationsByDist);

export const useSortLocations = (skip?: boolean): void => {
  const dispatch = useDispatch();
  const userPosition = useGeolocation();

  const {regionSlug: selectedRegion} = useUserSelectedLocation();
  const {
    config: {locationsSorted, locations, pageLoaded},
  } = useTypedSelector(state => state);

  useEffect(() => {
    if (!skip && !locationsSorted && userPosition && locations && locations.length) {
      const sortedLocations = sortLocationsByPoint(locations, userPosition);
      const maybeNewRegionSlug = selectedRegion || sortedLocations[0].region.slug;

      dispatch(
        actions.setConfig({
          locations: sortedLocations,
          locationsSorted: true,
        }),
      );
      setUserSelectedLocation({regionSlug: maybeNewRegionSlug});
    }

    if (!pageLoaded) {
      dispatch(
        actions.setConfig({
          pageLoaded: true,
        }),
      );
    }
  }, [dispatch, locations, locationsSorted, pageLoaded, selectedRegion, skip, userPosition]);
};
